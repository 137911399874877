a:hover .flatButton {
  text-decoration: none;
}

.flatButton {
  text-decoration: none;
  padding: 8px 10px;
  margin: 0;
  background-color: transparent;
  color: var(--text);
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  min-height: 36px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 4px;


  &:disabled {
  }

  &:focus {
  }

  &:global(.lower) {
    min-height: 30px;
    height: 30px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  /* BLUE */

  &:global(.blue) {
    text-transform: none;
    background-color: var(--blue_light);
    border-color: var(--blue_light);
    color: var(--white);

    &:hover,
    &:active {
      background-color: var(--blue);
      border-color: var(--blue_light);
    }

    &:disabled {
      background-color: transparent;
      border: 1px solid var(--button_disable_border);
    }
  }

  /* GREY */

  &:global(.grey) {
    text-transform: none;
    border: 1px solid var(--button_grey_border);
    background-color: var(--button_grey);

    &:hover,
    &:active {
      background-color: var(--button_grey_hover);
    }

    &:disabled {
      border: 1px solid var(--button_disable_border);
      background-color: transparent;

    }
  }

  /* SALMON */

  &:global(.salmon) {
    text-transform: none;
    background-color: var(--button_salmon);
    border-color: var(--button_salmon);
    color: var(--white);

    &:hover,
    &:active {
      background-color: var(--button_salmon_hover);
      border-color: var(--button_salmon_hover);
    }

    &:disabled {
      background-color: transparent;
      border: 1px solid var(--button_disable_border);
    }

    &:not(.disabled):focus {
    }
  }

  /* BLACK */

  &:global(.black) {
    text-transform: none;
    background-color: var(--button_black);
    border-color: var(--button_black);
    color: var(--white);

    &:hover,
    &:active {
      background-color: var(--button_black_hover);
      border-color: var(--button_black_hover);
    }

    &:disabled {
      background-color: transparent;
      border: 1px solid var(--button_disable_border);
    }
  }

  /* BLACK TEXT */

  &:global(.blackText) {
    text-transform: none;
    color: var(--black_light);
    border: 0;

    &:hover,
    &:active {
      text-decoration: underline;
      background-color: transparent;
    }
  }
}

.defaultWidth {
  width: 145px;
}
