.errorPageTitle {
  font-size: 5rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.errorDetailsSectionIntroduction {
  font-size: 1.6rem;
}

.errorDetails {
  max-width: 700px;
  border-top: solid 1px var(--black_light);
  padding: 0.5rem 0;
}

.errorDetailsTitle {
  font-weight: bold;
  font-size: 1.1rem;
}

.errorActionButtonsContainer {
  padding: 0.5rem 0;


  > a + a,
  > a + button,
  > button + a,
  > button + button {
    margin: 0 4px;
  }
}
