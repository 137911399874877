@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,700,700i&display=swap&subset=latin-ext');


html body {
  color: var(--text);
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  color: var(--blue_light);
  text-decoration: none;
  background-color: var(--transparent);

  &:hover {
    color: var(--blue);
    text-decoration: underline;
  }
}
