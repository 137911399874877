@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,700,700i&display=swap&subset=latin-ext);
@-webkit-keyframes circularLoadingIndicator_rotator__1k2rm {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }

@keyframes circularLoadingIndicator_rotator__1k2rm {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }

@-webkit-keyframes circularLoadingIndicator_colors__6GdBn {
  0% {
    stroke: var(--green); }
  50% {
    stroke: var(--blue_light); }
  100% {
    stroke: var(--red_light); } }

@keyframes circularLoadingIndicator_colors__6GdBn {
  0% {
    stroke: var(--green); }
  50% {
    stroke: var(--blue_light); }
  100% {
    stroke: var(--red_light); } }

@-webkit-keyframes circularLoadingIndicator_dash__3lEz0 {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); } }

@keyframes circularLoadingIndicator_dash__3lEz0 {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); } }

.circularLoadingIndicator_spinnerContainer__3_Lyx {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px; }

.circularLoadingIndicator_spinner__2a0E3 {
  -webkit-animation: circularLoadingIndicator_rotator__1k2rm 1.4s linear infinite;
          animation: circularLoadingIndicator_rotator__1k2rm 1.4s linear infinite; }
  .circularLoadingIndicator_spinner__2a0E3 circle {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-animation: circularLoadingIndicator_dash__3lEz0 1.4s ease-in-out infinite, circularLoadingIndicator_colors__6GdBn 5.6s ease-in-out infinite;
            animation: circularLoadingIndicator_dash__3lEz0 1.4s ease-in-out infinite, circularLoadingIndicator_colors__6GdBn 5.6s ease-in-out infinite; }

a:hover .flatButton_flatButton__2Bm6t {
  text-decoration: none; }

.flatButton_flatButton__2Bm6t {
  text-decoration: none;
  padding: 8px 10px;
  margin: 0;
  background-color: transparent;
  color: var(--text);
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  min-height: 36px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 4px;
  /* BLUE */
  /* GREY */
  /* SALMON */
  /* BLACK */
  /* BLACK TEXT */ }
  .flatButton_flatButton__2Bm6t.lower {
    min-height: 30px;
    height: 30px;
    padding-top: 4px;
    padding-bottom: 4px; }
  .flatButton_flatButton__2Bm6t.blue {
    text-transform: none;
    background-color: var(--blue_light);
    border-color: var(--blue_light);
    color: var(--white); }
    .flatButton_flatButton__2Bm6t.blue:hover, .flatButton_flatButton__2Bm6t.blue:active {
      background-color: var(--blue);
      border-color: var(--blue_light); }
    .flatButton_flatButton__2Bm6t.blue:disabled {
      background-color: transparent;
      border: 1px solid var(--button_disable_border); }
  .flatButton_flatButton__2Bm6t.grey {
    text-transform: none;
    border: 1px solid var(--button_grey_border);
    background-color: var(--button_grey); }
    .flatButton_flatButton__2Bm6t.grey:hover, .flatButton_flatButton__2Bm6t.grey:active {
      background-color: var(--button_grey_hover); }
    .flatButton_flatButton__2Bm6t.grey:disabled {
      border: 1px solid var(--button_disable_border);
      background-color: transparent; }
  .flatButton_flatButton__2Bm6t.salmon {
    text-transform: none;
    background-color: var(--button_salmon);
    border-color: var(--button_salmon);
    color: var(--white); }
    .flatButton_flatButton__2Bm6t.salmon:hover, .flatButton_flatButton__2Bm6t.salmon:active {
      background-color: var(--button_salmon_hover);
      border-color: var(--button_salmon_hover); }
    .flatButton_flatButton__2Bm6t.salmon:disabled {
      background-color: transparent;
      border: 1px solid var(--button_disable_border); }
  .flatButton_flatButton__2Bm6t.black {
    text-transform: none;
    background-color: var(--button_black);
    border-color: var(--button_black);
    color: var(--white); }
    .flatButton_flatButton__2Bm6t.black:hover, .flatButton_flatButton__2Bm6t.black:active {
      background-color: var(--button_black_hover);
      border-color: var(--button_black_hover); }
    .flatButton_flatButton__2Bm6t.black:disabled {
      background-color: transparent;
      border: 1px solid var(--button_disable_border); }
  .flatButton_flatButton__2Bm6t.blackText {
    text-transform: none;
    color: var(--black_light);
    border: 0; }
    .flatButton_flatButton__2Bm6t.blackText:hover, .flatButton_flatButton__2Bm6t.blackText:active {
      text-decoration: underline;
      background-color: transparent; }

.flatButton_defaultWidth__1vpR5 {
  width: 145px; }

.errorMessage_errorPageTitle__1oCni {
  font-size: 5rem;
  margin-top: 0;
  margin-bottom: 0.5rem; }

.errorMessage_errorDetailsSectionIntroduction__1pjhs {
  font-size: 1.6rem; }

.errorMessage_errorDetails__1sPa7 {
  max-width: 700px;
  border-top: solid 1px var(--black_light);
  padding: 0.5rem 0; }

.errorMessage_errorDetailsTitle__3UC7K {
  font-weight: bold;
  font-size: 1.1rem; }

.errorMessage_errorActionButtonsContainer__phZFM {
  padding: 0.5rem 0; }
  .errorMessage_errorActionButtonsContainer__phZFM > a + a,
  .errorMessage_errorActionButtonsContainer__phZFM > a + button,
  .errorMessage_errorActionButtonsContainer__phZFM > button + a,
  .errorMessage_errorActionButtonsContainer__phZFM > button + button {
    margin: 0 4px; }

#noty_layout__topRight {
  top: 100px; }

html body {
  color: var(--text);
  margin: 0; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

a {
  color: var(--blue_light);
  text-decoration: none;
  background-color: var(--transparent); }
  a:hover {
    color: var(--blue);
    text-decoration: underline; }

