$offset: 187;
$duration: 1.4s;


@keyframes rotator {

  0% { transform: rotate(0deg); }

  100% { transform: rotate(270deg); }
}

@keyframes colors {
  0% { stroke: var(--green); }

  50% { stroke: var(--blue_light); }

  100% { stroke: var(--red_light); }
}

@keyframes dash {

  0% { stroke-dashoffset: $offset; }

  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

// Loader
.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px;
}

.spinner {
  animation: rotator $duration linear infinite;


  circle {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite,
      colors ($duration*4) ease-in-out infinite;
  }
}
